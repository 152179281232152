<template>
  <div>
    <div style="padding: 35px; padding-top: 15px">
      <transition name="tour-guide-fade">
        <div
          v-if="showTitles"
          class="py-5 text-2xl font-weight-bold"
        >
          {{ $t('interests_package') }}
        </div>
      </transition>

      <div
        class="justify-center align-center"
        style="padding-top: 80px"
      >
        <transition name="tour-guide-fade">
          <v-btn
            v-if="showTitles"
            block
            class="my-2 rounded-lg"
            outlined
            x-large
            @click="moveToNextSlide(1)"
          >
            {{ $t('only_messages') }}
          </v-btn>
        </transition>
        <transition name="tour-guide-fade">
          <v-btn
            v-if="showTitles"
            block
            class="rounded-lg"
            outlined
            x-large
            @click="moveToNextSlide(2)"
          >
            {{ $t('calls_with_messages') }}
          </v-btn>
        </transition>
      </div>
    </div>
    <transition name="tour-guide-image-fade">
      <PreloadedImage
        v-if="showTitles"
        :src="require('@/assets/images/tour-guide/conversation.png')"
        style="opacity: 0.5; margin-top:50px"
        height="170"
        contain
      ></PreloadedImage>
    </transition>
  </div>
</template>

<script>
import store from '@/store'
import { useTourGuideTitleAnimation } from '../useTourGuideTitleAnimation'

export default {
  name: 'Package',

  props: {
    moveToNextStep: {
      type: Function,
      required: true,
    },
    currentStep: {
      required: true,
    },
    stepIndex: {
      required: true,
    },
  },

  setup(props, { emit }) {
    const { showTitles } = useTourGuideTitleAnimation(props)
    emit('update:selectedPackage', null)

    const moveToNextSlide = type => {
      emit('update:selectedPackage', type)
      const includeCallsPreference = type !== 1

      store.dispatch('packagePreference', { includeCallsPreference, id: store.state.eventData.id }).then(() => {
        props.moveToNextStep()
      })
    }

    return {
      moveToNextSlide,
      showTitles,
    }
  },
}
</script>
